import apiClient from './apiClient';

export default {
  getRecentAttendees: () => apiClient.get('/user/recent-contacts'),
  getUserSettings: () => apiClient.get('/user/settings'),
  updateUser: (params) => apiClient.put('/user', { ...params }),
  setActiveUserRole: (params) => apiClient.patch('/user/active-role', { ...params }),
  updatePassword: (params) => apiClient.put('/user/update-password', { ...params }),
  setResizedPhoto: (params) => apiClient.put('/user/set-resized-photo', { ...params }),
  sessionCheck: () => apiClient.get('/session/check'),
  checkIntegrationAvailability: (params) => apiClient.get('/session/check-integration-availability', { params }),
  getNotifications: () => apiClient.get('/user/notification'),
  updateNotification: (params) => apiClient.put('/user/notification', { ...params }),
  updateSecondaryEmails: (params) => apiClient.put('/user/set-additional-emails', { ...params }),
  getUserAttachments: () => apiClient.get('/user/attachments'),
  updateAttachmentTitle: (params) => apiClient.put(`/user/attachments/${params.id}`, { ...params }),
  deleteAttachment: (id) => apiClient.delete(`/user/attachments/${id}`),
  saveAttachment: (params) => apiClient.post('/user/attachments', { ...params }),
  getUserBrandingSettings: (params) => apiClient.get('/user/branding-settings', { params }),
  getEmailPreview: (params) => apiClient.get('/user/email/preview', { params, responseType: 'blob' }),
  saveUserSettings: (params) => apiClient.put('/user/settings', { ...params }),
  getUserLogos: (params) => apiClient.get('/user/logos', { params }),
  saveUserLogo: (params) => apiClient.post('/user/logo', { ...params }),
  deleteUserLogo: (logoId) => apiClient.delete(`/user/logo/${logoId}`),
  activateUserLogo: (params) => apiClient.put('/user/logo/activate', { ...params }),
  deactivateUserLogo: (params) => apiClient.put('/user/logo/deactivate', { ...params }),
  updateCompanyLogo: (companyId, params) => apiClient.patch(`/user/companies/${companyId}/logos`, { ...params }),
  saveEmailCta: (params) => apiClient.patch('/user/save-email-cta', { ...params }),
  agentMoveVideo: (videoId, params) => apiClient.patch(`/user/videos/${videoId}/move-to-property`, { ...params }),
  getUserPins: (params) => apiClient.get('/user/pins', { params }),
  updatePin: (pinId, params) => apiClient.patch(`/user/pins/${pinId}`, { ...params }),
  deletePin: (pinId) => apiClient.delete(`/user/pins/${pinId}`),
  putPins: (params) => apiClient.put('/user/pins', { ...params }),
  getProperties: (params) => apiClient.get('/user/properties', { params }),
  updateActiveProperty: (params) => apiClient.patch('/user/active-property', { ...params }),
  checkPropertyAvailability: (id, params) => apiClient.get(`/user/${id}/check-property-availability`, { params }),
  getActivityFeed: () => apiClient.get('/user/prospects/activity-feed'),

  // Settings -> Accessibility
  updateCompanySettings: (companyId, params) => apiClient.patch(`/user/company/${companyId}/settings`, { ...params }),
  copyVideo: (videoId, params) => apiClient.post(`/user/videos/${videoId}/copy-to-property`, { ...params }),
  getUserIntegrationSettings: (params) => apiClient.get('/user/integrations/integration-settings', { params })
};
