import PrivacyPolicy from '@/components/pages/PrivacyPolicy.vue';

export const videoLinkRoute = {
  path: '/video/:videoId',
  name: 'VideoLink',
  component: () => import('@/components/pages/SharedVideo.vue'),
  meta: {
    skipBrowserNotSupported: true,
    title: 'Video | Real Estate Video Tours',
    publicPage: true
  }
};

export const videoEditRoutes = {
  path: '/',
  component: {
    template: '<router-view></router-view>'
  },
  children: [
    {
      path: '/videos/:videoId/edit',
      name: 'VideoEdit',
      component: () => import('@/components/videos/edit/main.vue')
    }
  ]
};

export const reviewRedirectRoute = {
  path: '/video-review-process',
  component: () => import('@/components/pages/PageLoading.vue')
};

export const inAppRoutes = {
  path: '/',
  component: () => import('@/components/layout/main.vue'),
  children: [
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/components/home/dashboard/main.vue')
    },
    {
      path: '/media',
      name: 'MediaNew', // TODO: rename to Media after removal of old path below  -`/videos`
      component: () => import('@/components/media/main.vue'),
      children: [
        {
          path: 'list',
          name: 'MediaListNew', // TODO: rename to Media after removal of old path below  -`/videos`
          component: () => import('@/components/videos/main.vue')
        }
      ]
    },
    {
      path: '/videos',
      name: 'Media',
      component: () => import('@/components/videos/main.vue'),
      children: [
        {
          path: 'list',
          name: 'MediaList',
          component: () => import('@/components/videos/main.vue')
        }
      ]
    },
    {
      path: '/home/analytics',
      component: () => import('@/components/home/analytics/main.vue'),
      name: 'MainAnalytics',
      redirect: { name: 'AnalyticsUsage' },
      children: [
        {
          path: 'usage',
          name: 'AnalyticsUsage',
          component: () => import('@/components/home/analytics/usage/main.vue')
        },
        {
          path: 'engagement',
          name: 'AnalyticsEngagement',
          component: () => import('@/components/home/analytics/engagement/main.vue')
        },
        {
          path: 'insights',
          name: 'AnalyticsInsights',
          component: () => import('@/components/home/analytics/insights/main.vue')
        },
        {
          path: 'leaderboard',
          name: 'AnalyticsLeaderboard',
          component: () => import('@/components/home/analytics/leaderboard/main.vue')
        },
        {
          path: 'live_event_insights',
          name: 'AnalyticsLiveEventInsights',
          component: () => import('@/components/home/analytics/liveEventInsights/main.vue')
        }
      ]
    },
    {
      path: '/home/reporting',
      name: 'ExportPropertiesData',
      component: () => import('@/components/home/reporting/main.vue')
    },
    {
      path: '/home/users-report',
      name: 'UsersReport',
      component: () => import('@/components/home/users/main.vue')
    },
    {
      path: '/properties',
      component: () => import('@/components/properties/main.vue'),
      name: 'PropertiesIndex',
      children: [
        {
          path: 'list',
          name: 'PropertiesList',
          component: () => import('@/components/properties/main.vue')
        }
      ]
    },
    {
      path: '/properties/:propertyId',
      component: () => import('@/components/properties/show.vue'),
      name: 'PropertiesShow',
      redirect: { name: 'PropertyMedia' },
      children: [
        {
          path: 'videos',
          name: 'PropertyMedia',
          component: () => import('@/components/properties/show/PropertyMedia.vue')
        },
        {
          path: ':liveType',
          name: 'PropertyLiveSection',
          component: () => import('@/components/properties/show/LiveSection.vue')
        }
      ]
    },
    {
      path: '/live/tours',
      name: 'LiveTours',
      component: () => import('@/components/live/main.vue')
    },
    {
      path: '/live/tours/schedule',
      name: 'ScheduleTour',
      component: () => import('@/components/live/common/schedule/main.vue')
    },
    {
      path: '/live/tours/edit/:tourId',
      name: 'EditTour',
      component: () => import('@/components/live/common/schedule/main.vue')
    },
    {
      path: '/live/sessions',
      name: 'LiveSessions',
      component: () => import('@/components/live/main.vue')
    },
    {
      path: '/live/sessions/schedule',
      name: 'ScheduleSession',
      component: () => import('@/components/live/common/schedule/main.vue')
    },
    {
      path: '/live/sessions/edit/:liveSessionId',
      name: 'EditSession',
      component: () => import('@/components/live/common/schedule/main.vue')
    },
    {
      path: '/prospects',
      name: 'ProspectsIndex',
      component: () => import('@/components/activity/main.vue')
    },
    {
      path: '/prospects/:prospectId',
      name: 'ProspectsShow',
      component: () => import('@/components/activity/details/main.vue')
    },
    {
      path: '/settings',
      name: 'Settings',
      redirect: '/settings/account',
      component: () => import('@/components/settings/main.vue'),
      children: [
        {
          path: 'account',
          name: 'SettingsAccount',
          component: () => import('@/components/settings/account/main.vue')
        },
        {
          path: 'customization',
          name: 'SettingsCustomization',
          component: () => import('@/components/settings/customization/main.vue')
        },
        {
          path: 'notifications',
          name: 'SettingsNotifications',
          component: () => import('@/components/settings/notifications/main.vue')
        },
        {
          path: 'live_tour_settings',
          name: 'SettingsLiveTour',
          component: () => import('@/components/settings/live/main.vue')
        },
        {
          path: 'attachments',
          name: 'SettingsAttachments',
          component: () => import('@/components/settings/attachments/main.vue')
        },
        {
          path: 'accessibility',
          name: 'SettingsAccessibility',
          component: () => import('@/components/settings/accessibility/main.vue')
        },
        {
          path: 'security',
          name: 'SettingsSecurity',
          component: () => import('@/components/settings/security/main.vue')
        },
        {
          path: 'integrations',
          name: 'SettingsIntegrations',
          component: () => import('@/components/settings/integrations/main.vue')
        }
      ]
    }
  ]
};

export const publicRoutes = {
  path: '/',
  component: {
    template: '<router-view></router-view>'
  },
  children: [
    {
      path: '/privacy_policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        publicPage: true,
        title: 'Realync | Privacy Policy'
      }
    },
    {
      path: '/past_video/past_session/:id',
      name: 'PastSession',
      component: () => import('@/components/live/sessions/past/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Past Session'
      }
    },
    {
      path: '/sign_in',
      name: 'SignIn',
      component: () => import('@/components/login/main.vue'),
      meta: {
        mobileNotSupported: true,
        publicPage: true,
        title: 'Realync | Sign In',
        redirectIfAuthorized: true
      }
    },
    {
      path: '/single-sign-on',
      name: 'SingleSignOn',
      component: () => import('@/components/login/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Single Sign On',
        redirectIfAuthorized: true,
        mobileNotSupported: true
      }
    },
    {
      path: '/multi-factor-authentication',
      name: 'MultiFactorAuthentication',
      component: () => import('@/components/login/main.vue'),
      meta: {
        title: 'Realync | Multi Factor Authentication',
        publicPage: true,
        redirectIfAuthorized: false
      }
    },
    {
      path: '/forgot_password',
      name: 'ForgotPassword',
      component: () => import('@/components/login/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Forgot Password',
        redirectIfAuthorized: true
      }
    },
    {
      path: '/reset_password/:resetHash',
      name: 'ResetPassword',
      component: () => import('@/components/login/PasswordActions.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Reset Password',
        redirectIfAuthorized: true
      }
    },
    {
      path: '/create_password/:resetHash',
      name: 'CreatePassword',
      component: () => import('@/components/login/PasswordActions.vue'),
      meta: {
        title: 'Realync | Change Password',
        publicPage: true,
        redirectIfAuthorized: true
      }
    },
    {
      path: '/browser_not_supported',
      name: 'BrowserNotSupported',
      component: () => import('@/components/pages/BrowserNotSupported.vue'),
      meta: {
        publicPage: true
      }
    },
    {
      path: '/loading',
      name: 'PageLoading',
      component: () => import('@/components/pages/PageLoading.vue'),
      meta: {
        publicPage: true
      }
    },
    {
      path: '/agent-busy',
      name: 'AgentBusy',
      component: () => import('@/components/pages/AgentBusy.vue'),
      meta: {
        skipBrowserNotSupported: true,
        title: 'Realync | Agent is busy',
        publicPage: true
      }
    },
    {
      path: '/link-invalid',
      name: 'LinkInvalid',
      component: () => import('@/components/pages/LinkInvalid.vue'),
      meta: {
        skipBrowserNotSupported: true,
        title: 'Realync | Link is invalid',
        publicPage: true
      }
    },
    {
      path: '/live_session/static/:userId',
      name: 'LiveSessionStatic',
      component: () => import('@/components/live/sessions/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync'
      }
    },
    {
      path: '/share',
      component: {
        template: '<router-view></router-view>'
      },
      children: [
        {
          path: ':type/:recordId',
          name: 'SharedVideo',
          component: () => import('@/components/sharePage/main.vue'),
          meta: {
            publicPage: true,
            skipBrowserNotSupported: true,
            title: 'Video | Real Estate Video Tours'
          }
        },
        {
          path: ':type/:recordId',
          name: 'SharedPlaylist',
          component: () => import('@/components/sharePage/main.vue'),
          meta: {
            publicPage: true,
            skipBrowserNotSupported: true,
            title: 'Playlist | Real Estate Video Tours'
          }
        }
      ]
    },
    {
      path: '/live',
      component: {
        template: '<router-view></router-view>'
      },
      children: [
        {
          path: 'session/:liveSessionId',
          name: 'LiveSession',
          component: () => import('@/components/live/sessions/main.vue'),
          meta: {
            publicPage: true,
            title: 'Realync'
          }
        }
      ]
    },
    {
      path: '/live_video/tour',
      name: 'LiveVideoTour',
      component: () => import('@/components/live/tours/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Live Tour'
      }
    },
    {
      path: '/live_video/tour/:tourId/:propertyId',
      name: 'LiveVideoTourStubPage',
      component: () => import('@/components/live/tours/deepLinkStubPage.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Live Tour'
      }
    },
    {
      path: '/live_tour/static/:userId',
      name: 'LiveTourStatic',
      component: () => import('@/components/live/tours/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Live Tour'
      }
    },
    {
      path: '/live_video/register/:tourId',
      name: 'RegisterPageRSVP',
      component: () => import('@/components/live/tours/register/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Live Tour'
      }
    },
    {
      path: '/past_video/past',
      name: 'PastVideoTour',
      component: () => import('@/components/live/tours/past/main.vue'),
      meta: {
        publicPage: true,
        title: 'Realync | Past Tour'
      }
    }
  ]
};

export default {
  videoEditRoutes,
  videoLinkRoute,
  reviewRedirectRoute,
  inAppRoutes,
  publicRoutes
};
