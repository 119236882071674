import _ from 'underscore';
import API from '@api/share.api';
import videoAPI from '@api/hostVideo.api';
import { getAllTimeRanges, getRange, getIndexes } from '@helpers/closedCaptions.helper';

function initialState() {
  return {
    video: {},
    loading: true,
    invalidVideo: false,
    allTimeRanges: [],
    currentRange: {},
    currentTime: 0,
    previousTime: 0,
    hostVideoWatchId: null,
    recipient: {}
  };
}

function normalizeSegments(segments) {
  return segments.map((segment) => ({ ...segment, active: false }));
}

const getters = {
  accessibility: (state) => state.video.accessibility,
  allTimeRanges: (state) => state.allTimeRanges,
  currentRange: (state) => state.currentRange,
  captionsPresent: (state) => (
    state.video.accessibility
      && state.video.accessibility.accessibility
      && (state.video.accessibility.transcriptionStatus === 'complete')
      && (state.video.accessibility.transcriptionText?.length > 0)
  ),
  video: (state) => state.video,
  loading: (state) => state.loading,
  invalidVideo: (state) => state.invalidVideo,
  hostVideoWatchId: (state) => state.hostVideoWatchId,
  recipient: (state) => state.recipient
};

const mutations = {
  SET_VIDEO_RECORD(state, data) {
    state.video = data;
  },
  SET_VIDEO_SEGMENTS(state, payload) {
    state.video.segments = payload;
  },
  SET_HOST_VIDEO_WATCH_ID(state, payload) {
    state.hostVideoWatchId = payload;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_INVALID_VIDEO(state, payload) {
    state.invalidVideo = payload;
  },
  SET_ALL_TIME_RANGES(state, payload) {
    state.allTimeRanges = payload;
  },
  SET_ACTIVE_SEGMENT(state, segmentIndex) {
    state.video.segments[segmentIndex].active = true;
  },
  DEACTIVATE_SEGMENTS(state, currentActiveSegment) {
    state.video.segments.forEach((segment) => {
      if (segment !== currentActiveSegment) {
        segment.active = false;
      }
    });
  },
  SET_CURRENT_TIME_RANGE(state) {
    const { currentTime, previousTime, allTimeRanges } = state;

    const indexes = getIndexes(currentTime, previousTime, allTimeRanges);

    if (!indexes || !(indexes && Object.keys(indexes).length)) return;

    const { currentTimeRangeIndex, previousTimeRangeIndex } = indexes;

    if (previousTimeRangeIndex >= 0) allTimeRanges[previousTimeRangeIndex].current = false;
    if (currentTimeRangeIndex >= 0) allTimeRanges[currentTimeRangeIndex].current = true;

    state.currentRange = getRange(currentTime, allTimeRanges);
  },
  SET_CURRENT_TIME(state, time) {
    state.previousTime = state.currentTime;
    state.currentTime = time;
  },
  SET_RECIPIENT(state, payload) {
    state.recipient = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getSharedVideo({ commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const response = await API.getSharedVideo(params.videoId, params.query);

    if (response.status === 200) {
      const { record } = response.data;

      if (_.isEmpty(record) || record.deleted) {
        commit('SET_INVALID_VIDEO', true);
      } else {
        dispatch('setRecipient', record.recipient);
        dispatch('setActiveVideo', record);
      }
    } else {
      commit('SET_INVALID_VIDEO', true);
    }
  },
  async getVideoById({ commit }, id) {
    commit('SET_LOADING', true);

    const response = await videoAPI.getVideoById(id);

    if (response.status === 200) {
      const { record } = response.data;

      if (_.isEmpty(record) || record.deleted) {
        commit('SET_INVALID_VIDEO', true);
      } else {
        await commit('SET_VIDEO_RECORD', record);
      }
    } else {
      commit('SET_INVALID_VIDEO', true);
    }

    commit('SET_LOADING', false);
  },
  async setVideoSegment({ state, commit, dispatch }) {
    let { segments } = state.video;

    if (!['VR', 'Click Through'].includes(state.video.type) && segments) {
      if (!segments.length && state.video.forceLoadSegments) {
        segments = await dispatch('getVideoSegments');
      }

      if (!segments.length) {
        segments = await dispatch('getVideoSegmentsByClips', state.video._id);
      }

      if (segments.length) {
        segments = normalizeSegments(segments);
      }
    }

    commit('SET_VIDEO_SEGMENTS', segments);
  },
  async setActiveVideo({ commit, dispatch }, video) {
    commit('SET_LOADING', true);

    await commit('SET_VIDEO_RECORD', video);
    await dispatch('setVideoSegment');
    await dispatch('setAllTimeRanges');

    commit('SET_LOADING', false);
  },
  setRecipient({ commit }, payload) {
    commit('SET_RECIPIENT', payload);
  },
  async logRecipientWatched({ commit }, params) {
    const {
      recipientId,
      prospectId,
      source
    } = params;

    const response = await API.logRecipientWatched(recipientId, {
      prospectId,
      source
    });

    if (response.status === 200 && response.data.record?.id) {
      commit('SET_HOST_VIDEO_WATCH_ID', response.data.record.id);
    }
  },
  async logRecipientWatchedPlaylistVideo({ state, commit }, playlistId) {
    const response = await API.logRecipientWatchedPlaylistVideo({
      playlistId,
      hostVideoId: state.video.id,
      trackingId: state.recipient.id
    });

    if (response.status === 200 && response.data.record?.id) {
      commit('SET_HOST_VIDEO_WATCH_ID', response.data.record.id);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getVideoSegmentsByClips({ state }, videoId) {
    if (videoId) {
      const response = await API.getVideoSegmentsByClips(videoId);

      if (response.status === 200) {
        return response.data.records;
      }
    }

    return [];
  },
  async getVideoSegments({ state }) {
    const response = await API.getVideoSegments(state.video.id);

    if (response.status === 200) {
      return response.data.records;
    }

    return [];
  },
  // eslint-disable-next-line no-shadow
  setAllTimeRanges({ state, commit, getters }) {
    let allTimeRanges = [];

    if (getters.captionsPresent) {
      const { transcriptionJSON } = state.video.accessibility;
      const transcriptionJSONCopy = transcriptionJSON.map((transcription) => ({ ...transcription }));

      allTimeRanges = getAllTimeRanges(transcriptionJSONCopy);
    }

    commit('SET_ALL_TIME_RANGES', allTimeRanges);
  },
  setCurrentTimeRange({ commit }) {
    commit('SET_CURRENT_TIME_RANGE');
  },
  setCurrentTime({ commit }, time) {
    commit('SET_CURRENT_TIME', time);
  },
  setActiveSegment({ commit }, segmentIndex) {
    commit('SET_ACTIVE_SEGMENT', segmentIndex);
  },
  deactivateSegments({ commit }, currentActiveSegment) {
    commit('DEACTIVATE_SEGMENTS', currentActiveSegment);
  },
  resetSharedVideo({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
