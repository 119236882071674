import moment from 'moment';
import _ from 'underscore';
import API from '@api/liveSession.api';

function initialState() {
  return {
    attachmentsTabLoading: false,
    currentLiveStep: 'join_form',
    currentProspect: {},
    currentGuestId: null,
    currentParticipant: {},
    editableNote: false,
    mapCategoriesLoading: false,
    messagesTabLoading: false,
    noteTabLoading: false,
    liveSessionLoading: false,
    agendaContentLoading: false,
    tokenLoading: false,
    isFullScreen: false,
    liveSession: {
      device: {
        cameraEnabled: true,
        microphoneEnabled: true
      },
      attachments: [],
      defaultPins: [],
      mapCategories: [],
      messages: [],
      invitedAttendees: [],
      note: '',
      record: {},
      notFound: false,
      agendaContent: [],
      token: '',
      participantDataTrack: null,
      participants: 0,
      screenShare: false,
      screenTrack: null
    },
    presentMode: false
  };
}

const getters = {
  attachments: (state) => state.liveSession.attachments,
  attachmentsTabLoading: (state) => state.attachmentsTabLoading,
  currentLiveStep: (state) => state.currentLiveStep,
  currentProspect: (state) => state.currentProspect,
  currentGuestId: (state) => state.currentGuestId,
  currentParticipant: (state) => state.currentParticipant,
  defaultPins: (state) => state.liveSession.defaultPins,
  editableNote: (state) => state.editableNote,
  liveSession: (state) => state.liveSession.record,
  liveSessionLoading: (state) => state.liveSessionLoading,
  liveSessionNotFound: (state) => state.liveSession.notFound,
  mapCategories: (state) => state.liveSession.mapCategories,
  mapCategoriesLoading: (state) => state.mapCategoriesLoading,
  messages: (state) => state.liveSession.messages,
  messagesTabLoading: (state) => state.messagesTabLoading,
  note: (state) => state.liveSession.note,
  noteTabLoading: (state) => state.liveSession.record.noteTabLoading,
  participantDataTrack: (state) => state.liveSession.participantDataTrack,
  presentMode: (state) => state.presentMode,
  agendaContent: (state) => state.liveSession.agendaContent,
  agendaContentLoading: (state) => state.agendaContentLoading,
  token: (state) => state.liveSession.token,
  cameraEnabled: (state) => state.liveSession.device.cameraEnabled,
  microphoneEnabled: (state) => state.liveSession.device.microphoneEnabled,
  participants: (state) => state.liveSession.participants,
  screenShare: (state) => state.liveSession.screenShare,
  isFullScreen: (state) => state.isFullScreen,
  screenTrack: (state) => state.liveSession.screenTrack,
  invitedAttendees: (state) => state.liveSession.invitedAttendees
};

const mutations = {
  SET_ATTACHMENTS(state, data) {
    state.liveSession.attachments = data;
  },
  SET_ATTACHMENTS_TAB_LOADING(state, loading) {
    state.attachmentsTabLoading = loading;
  },
  SET_CAMERA_ENABLED(state, cameraEnabled) {
    state.liveSession.device.cameraEnabled = cameraEnabled;
  },
  SET_CURRENT_LIVE_STEP(state, step) {
    state.currentLiveStep = step;
  },
  SET_CURRENT_PROSPECT(state, data) {
    state.currentProspect = data;
  },
  SET_CURRENT_GUEST_ID(state, data) {
    state.currentGuestId = data;
  },
  SET_CURRENT_PARTICIPANT(state, data) {
    state.currentParticipant = data;
  },
  SET_DEFAULT_PINS(state, data) {
    state.liveSession.defaultPins = data;
  },
  SET_EDITABLE_NOTE(state, data) {
    state.editableNote = data;
  },
  SET_LIVE_SESSION(state, data) {
    state.liveSession.record = data;
  },
  SET_LIVE_SESSION_LOADING(state, loading) {
    state.liveSessionLoading = loading;
  },
  SET_LIVE_SESSION_NOT_FOUND(state, notFound) {
    state.liveSession.notFound = notFound;
  },
  SET_MAP_CATEGORIES(state, data) {
    state.liveSession.mapCategories = data;
  },
  SET_MAP_CATEGORIES_LOADING(state, loading) {
    state.mapCategoriesLoading = loading;
  },
  SET_MESSAGES(state, data) {
    state.liveSession.messages = data;
  },
  ADD_MESSAGE(state, payload) {
    state.liveSession.messages.push(payload);
  },
  SET_MESSAGE_TAB_LOADING(state, loading) {
    state.messagesTabLoading = loading;
  },
  SET_MICROPHONE_ENABLED(state, microphoneEnabled) {
    state.liveSession.device.microphoneEnabled = microphoneEnabled;
  },
  SET_NOTE(state, note) {
    state.liveSession.note = note;
  },
  SET_NOTE_TAB_LOADING(state, loading) {
    state.noteTabLoading = loading;
  },
  SET_AGENDA_CONTENT(state, data) {
    state.liveSession.agendaContent = data;
  },
  SET_AGENDA_CONTENT_LOADING(state, loading) {
    state.agendaContentLoading = loading;
  },
  SET_TOKEN_LOADING(state, loading) {
    state.tokenLoading = loading;
  },
  SET_TOKEN(state, token) {
    state.liveSession.token = token;
  },
  UPDATE_LIVE_SESSION_RECORD(state, data) {
    state.liveSession.record = { ...state.liveSession.record, ...data };
  },
  SET_PARTICIPANT_DATA_TRACK(state, data) {
    state.liveSession.participantDataTrack = data;
  },
  SET_FULL_SCREEN_MODE(state, data) {
    state.isFullScreen = data;
  },
  SET_SCREEN_TRACK(state, data) {
    state.liveSession.screenTrack = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_PARTICIPANT_STATE(state, data) {
    state.liveSession.participants = data;
  },
  SET_SCREEN_SHARE_STATE(state, data) {
    state.liveSession.screenShare = data;
  },
  ADD_INVITED_ATTENDEE(state, data) {
    state.liveSession.invitedAttendees.push(data);
  },
  REMOVE_INVITED_ATTENDEE(state, email) {
    // eslint-disable-next-line max-len
    const index = state.liveSession.invitedAttendees.findIndex((attendeeEmail) => attendeeEmail === email);

    state.liveSession.invitedAttendees.splice(index, 1);
  },
  RESET_INVITED_ATTENDEES(state) {
    state.liveSession.invitedAttendees = [];
  }
};

const actions = {
  async getLiveSession({ commit, state }, liveSessionId) {
    commit('SET_LIVE_SESSION_LOADING', true);

    const response = await API.getLiveSession(liveSessionId, state.currentGuestId);

    if (response.status === 200) {
      const { record } = response.data;

      if (_.isEmpty(record)) {
        commit('SET_LIVE_SESSION_NOT_FOUND', true);
      } else {
        commit('SET_LIVE_SESSION', record);
      }
    } else {
      commit('SET_LIVE_SESSION_NOT_FOUND', true);
    }

    commit('SET_LIVE_SESSION_LOADING', false);
  },
  async deleteLiveSession({ dispatch }, liveSessionId) {
    await API.deleteLiveSession(liveSessionId);

    dispatch(
      'dashboardLiveSessionList/removeLiveSessionFromTheList',
      liveSessionId,
      { root: true }
    );
  },
  async getMessages({ commit, state }, currentUserId) {
    commit('SET_MESSAGE_TAB_LOADING', true);

    const { data } = await API.getLiveSessionMessages(state.liveSession.record.id);
    const serializedResponse = [];

    data.records.forEach((message) => {
      const currentUser = currentUserId ? message.guestId === currentUserId : message.agent;

      serializedResponse.push({
        type: 'message',
        text: message.text,
        sender: message.sender,
        currentUser, // params.guestId
        time: moment(message.createdAt).format('hh:mm a'),
        agent: message.agent,
        visible: true
      });
    });

    commit('SET_MESSAGES', serializedResponse);
    commit('SET_MESSAGE_TAB_LOADING', false);
  },
  addMessage({ commit }, payload) {
    commit('ADD_MESSAGE', payload);
  },
  async getAttachments({ dispatch, state }) {
    await dispatch(
      'attachments/getAttachments',
      {
        apiModule: 'LIVE_SESSION',
        recordId: state.liveSession.record.id
      },
      { root: true }
    );
  },
  async getNote({ commit, state }, email) {
    commit('SET_NOTE_TAB_LOADING', true);

    let note = '';
    const prospectEmail = email || undefined;

    const response = await API.getLiveSessionNote(
      state.liveSession.record.id,
      { prospectEmail }
    );

    if (response.status === 200) {
      const { record: noteRecord } = response.data;
      note = noteRecord ? noteRecord.content : '';
    }

    commit('SET_NOTE', note);
    commit('SET_NOTE_TAB_LOADING', false);

    return note;
  },
  async updateNote({ commit, state }, note) {
    const prospectEmail = state.currentProspect.email || undefined;

    await API.updateLiveSessionNote(
      state.liveSession.record.id,
      { note, prospectEmail }
    );

    commit('SET_NOTE', note);
  },
  async getMapCategories({ commit }) {
    commit('SET_MAP_CATEGORIES_LOADING', true);

    const { data } = await API.getMapCategories();

    data.records.unshift({ title: 'None', key: 'none' });

    commit('SET_MAP_CATEGORIES', data.records);
    commit('SET_MAP_CATEGORIES_LOADING', false);
  },
  async getDefaultPins({ commit, state }) {
    const { data } = await API.getLiveSessionPins(state.liveSession.record.id);

    commit('SET_DEFAULT_PINS', data.records);
  },
  async savePin({ commit, state }, params) {
    const { pins } = params;

    const response = await API.saveLiveSessionPin(
      state.liveSession.record.id,
      pins[0].identifier,
      {
        ...pins[0]
      }
    );

    if (response.status === 200) {
      commit('SET_DEFAULT_PINS', [...state.liveSession.defaultPins, { ...pins[0], defaultPin: false }]);
    }
  },
  async deletePin({ commit, state }, { pinId }) {
    await API.deleteLiveSessionPin(state.liveSession.record.id, pinId);

    const remainingPins = state.liveSession.defaultPins.filter((pin) => pin.identifier !== pinId);

    commit('SET_DEFAULT_PINS', remainingPins);
  },
  async registerProspect({ commit, state }, params) {
    const response = await API.registerProspect(
      state.liveSession.record.id,
      params
    );

    if (response.status === 200) {
      commit('SET_CURRENT_PROSPECT', response.data.record);
    }

    return response;
  },
  async getAgendaContent({ commit, state }) {
    commit('SET_AGENDA_CONTENT_LOADING', true);
    const response = await API.getAgendaContent(state.liveSession.record.id);

    if (response.status === 200) {
      commit('SET_AGENDA_CONTENT', response.data);
    }

    commit('SET_AGENDA_CONTENT_LOADING', false);
  },
  async updateLiveSessionUnit({ state }, unit) {
    await API.updateLiveSessionUnit(state.liveSession.record.id, unit);
  },
  setEditableNote({ commit }, data) {
    commit('SET_EDITABLE_NOTE', data);
  },
  setCurrentLiveStep({ commit }, step) {
    commit('SET_CURRENT_LIVE_STEP', step);
  },
  setCurrentParticipant({ commit }, participant) {
    commit('SET_CURRENT_PARTICIPANT', participant);
  },
  setCurrentGuestId({ commit }, guestId) {
    commit('SET_CURRENT_GUEST_ID', guestId);
  },
  setCameraEnabled({ commit }, cameraEnabled) {
    commit('SET_CAMERA_ENABLED', cameraEnabled);
  },
  setMicrophoneEnabled({ commit }, microphoneEnabled) {
    commit('SET_MICROPHONE_ENABLED', microphoneEnabled);
  },
  resetLiveSession({ commit }) {
    commit('RESET');
  },
  updateLiveSessionRecord({ commit }, data) {
    commit('UPDATE_LIVE_SESSION_RECORD', data);
  },
  setCameraState({ commit }, data) {
    commit('SET_CAMERA_ENABLED', data);
  },
  setAudioState({ commit }, data) {
    commit('SET_MICROPHONE_ENABLED', data);
  },
  setParticipantDataTrack({ commit }, data) {
    commit('SET_PARTICIPANT_DATA_TRACK', data);
  },
  setScreenShareMode({ commit }, data) {
    commit('SET_SCREEN_SHARE_STATE', data);
  },
  setFullScreenMode({ commit }, data) {
    commit('SET_FULL_SCREEN_MODE', data);
  },
  setScreenTrack({ commit }, data) {
    commit('SET_SCREEN_TRACK', data);
  },
  async saveMessage({ state }, data) {
    await API.saveMessage(state.liveSession.record.id, data);
  },
  setParticipantState({ commit }, data) {
    commit('SET_PARTICIPANT_STATE', data);
  },
  async guestWatched({ state }, params) {
    await API.guestWatched(state.liveSession.record.id, params);
  },
  // eslint-disable-next-line no-unused-vars
  async resendRecordedPastSession({ state }, sessionId) {
    const response = await API.resendRecordedPastSession(sessionId);

    return response;
  },
  async getToken({ commit, state }, params) {
    commit('SET_TOKEN_LOADING', true);

    const response = await API.getLiveSessionToken(
      state.liveSession.record.id,
      params
    );

    commit('SET_TOKEN', response.data.record.token);
    commit('SET_TOKEN_LOADING', false);
  },
  async agentJoinedLiveSession({ state }, userId) {
    const response = await API.agentJoinedLiveSession(state.liveSession.record.id, userId);

    return response;
  },
  async saveAgentTrack({ state }, data) {
    await API.saveAgentTrack(state.liveSession.record.id, data);
  },
  async completeLiveSession({ state }, roomSid) {
    await API.finishLiveSession(state.liveSession.record.id, roomSid);
  },
  async trackPlayedStreamVideo({ state }, data) {
    await API.trackPlayedStreamVideo(state.liveSession.record.id, data);
  },
  async enableScreenShare({ state }, data) {
    await API.enableScreenShare(state.liveSession.record.id, data);
  },
  async sendInviteEmails({ state }) {
    await API.sendInviteEmails(state.liveSession.record.id, {
      emails: state.liveSession.invitedAttendees
    });
  },
  getSessionBranding({ state, dispatch }) {
    dispatch(
      'brandingHeader/getBrandingHeader',
      { type: 'session', id: state.liveSession.record.id },
      { root: true }
    );
  },
  addInvitedAttendee({ commit }, data) {
    commit('ADD_INVITED_ATTENDEE', data);
  },
  removeInvitedAttendee({ commit }, data) {
    commit('REMOVE_INVITED_ATTENDEE', data);
  },
  resetInvitedAttendees({ commit }) {
    commit('RESET_INVITED_ATTENDEES');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
