<template>
  <div class="flex flex-col justify-center items-center py-10">
    <component :class="['mb-1', iconClasses]" :is="iconComponent" />
    <h6 class="my-2 font-semibold text-dark-400 text-sm">{{ title }}</h6>
    <p v-if="searching" class="text-center mt-1 font-semibold text-dark-400 text-sm">
      {{ searchingTitle }}
    </p>
    <p v-else-if="showSubTitle" class="text-center mt-1 font-semibold text-dark-400 text-sm">
      {{ subTitle }}
    </p>
    <slot name="additional-content" />
  </div>
</template>

<script>
export default {
  name: 'NoResults',
  components: {
    IconAgenda: () => import('@/assets/images/new-ui/no-results/agenda.svg'),
    IconAttachment: () => import('@/assets/images/new-ui/no-results/attachment.svg'),
    IconChartNoData: () => import('@/assets/images/new-ui/analytics/no_data.svg'),
    IconFolders: () => import('@/assets/images/new-ui/no-results/folders.svg'),
    IconPlaylist: () => import('@/assets/images/new-ui/no-results/playlist.svg'),
    IconUsersGrey: () => import('@/assets/images/new-ui/no-results/users.svg'),
    VRVideos: () => import('@/assets/images/new-ui/no-results/360.svg'),
    Videos: () => import('@/assets/images/new-ui/no-results/video.svg'),
    IconCalendar: () => import('@/assets/images/new-ui/no-results/calendar.svg'),
    Link: () => import('@/assets/images/new-ui/no-results/link.svg'),
    Music: () => import('@/assets/images/new-ui/no-results/music.svg'),
    IconJourney: () => import('@/assets/images/new-ui/no-results/journey.svg'),
    IconNoChat: () => import('@/assets/images/new-ui/no-results/no-chat.svg'),
    IconNoTasks: () => import('@/assets/images/new-ui/no-results/no-tasks.svg'),
    IconNotifications: () => import('@/assets/images/new-ui/no-results/notifications.svg'),
    IconNoNotes: () => import('@/assets/images/new-ui/no-results/no-notes.svg'),
    IconClosedCaptions: () => import('@/assets/images/new-ui/no-results/closed-captions.svg'),
    Gif: () => import('@/assets/images/new-ui/no-results/gif.svg'),
    NotFound: () => import('@/assets/images/new-ui/no-results/not-found.svg'),
    IconStar: () => import('@/assets/images/new-ui/no-results/star.svg')
  },
  props: {
    icon: {
      type: String,
      default: 'default'
    },
    iconClasses: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    searchingTitle: {
      type: String,
      default: 'Try other keywords'
    },
    searching: {
      type: Boolean,
      default: false
    },
    showSubTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconComponent() {
      const icons = {
        agenda: 'IconAgenda',
        'chart-no-data': 'IconChartNoData',
        folders: 'IconFolders',
        journey: 'IconJourney',
        attachment: 'IconAttachment',
        'no-chat': 'IconNoChat',
        'no-notes': 'IconNoNotes',
        'no-tasks': 'IconNoTasks',
        calendar: 'IconCalendar',
        notifications: 'IconNotifications',
        playlists: 'IconPlaylist',
        users: 'IconUsersGrey',
        videos: 'Videos',
        links: 'Link',
        'closed-captions': 'IconClosedCaptions',
        gif: 'Gif',
        music: 'Music',
        'vr-videos': 'VRVideos',
        'not-found': 'NotFound',
        star: 'IconStar'
      };

      return icons[this.icon];
    }
  }
};
</script>
