import apiClient from './apiClient';

export default {
  // Live Session: note
  getLiveSessionNote: (lsId, params) => apiClient.get(`/live/video-sessions/${lsId}/note`, { params }),
  updateLiveSessionNote: (lsId, params) => apiClient.put(`/live/video-sessions/${lsId}/note`, { ...params }),
  // Live Session: messages
  getLiveSessionMessages: (lsId) => apiClient.get(`/live/video-sessions/${lsId}/messages`),
  getLiveSession: (liveSessionId, guestId) => apiClient.get(
    `live/video-sessions/${liveSessionId}/session`,
    { params: { guestId } }
  ),
  getAttachments: (liveSessionId) => apiClient.get(`/live/video-sessions/${liveSessionId}/attachments`),
  resendRecordedPastSession: (liveSessionId) => apiClient.get(`/live/video-sessions/${liveSessionId}/resend-emails`),
  getLiveSessionPins: (liveSessionId) => apiClient.get(`/live/video-sessions/${liveSessionId}/pins`),
  saveLiveSessionPin: (liveSessionId, identifier, params) => apiClient.post(`/live/video-sessions/${liveSessionId}/pins/${identifier}`, { ...params }),
  deleteLiveSessionPin: (liveSessionId, identifier) => apiClient.delete(`/live/video-sessions/${liveSessionId}/pins/${identifier}`),
  registerProspect: (liveSessionId, params) => apiClient.post(
    `/live/video-sessions/${liveSessionId}/register-prospect`,
    { ...params },
    { silentErrorNotification: true }
  ),
  guestWatched: (liveSessionId, params) => apiClient.post(`live/video-sessions/${liveSessionId}/watched`, { ...params }),
  getAgendaContent: (liveSessionId) => apiClient.get(`/live/video-sessions/${liveSessionId}/agenda`),
  getLiveSessionToken: (liveSessionId, params) => apiClient.get(
    `/live/video-sessions/${liveSessionId}/token`,
    { params }
  ),
  agentJoinedLiveSession: (liveSessionId, userId) => apiClient.post(
    `/live/video-sessions/${liveSessionId}/agent-joined`,
    { userId },
    { silentErrorNotification: true }
  ),
  saveMessage: (liveSessionId, params) => apiClient.post(
    `live/video-sessions/${liveSessionId}/messages`,
    { ...params }
  ),
  saveAgentTrack: (liveSessionId, params) => apiClient.patch(`/live/video-sessions/${liveSessionId}/agent-tracks`, params),
  finishLiveSession: (liveSessionId, roomSid) => apiClient.get(`/live/twilio/room/complete?sessionId=${liveSessionId}&roomSid=${roomSid}`),
  trackPlayedStreamVideo: (liveSessionId, params) => apiClient.patch(`/live/video-sessions/${liveSessionId}/shared-video-ids`, params),
  // Full Library
  getFullLibraryVideos: (params) => apiClient.get('/live/video-sessions/library-videos', { params }),
  getFolders: (params) => apiClient.get('/live/video-sessions/folders', { params }),
  getFolderVideos: (folderId, params) => apiClient.get(`/live/video-sessions/folders/${folderId}/videos`, { params }),
  getFolderSubFolders: (folderId, params) => apiClient.get(`/live/video-sessions/folders/${folderId}/subfolders`, { params }),
  enableScreenShare: (liveSessionId, params) => apiClient.patch(
    `/live/video-sessions/${liveSessionId}/screenshare`,
    { ...params }
  ),
  sendInviteEmails: (liveSessionId, params) => apiClient.put(
    `/live/video-sessions/${liveSessionId}/invite-attendee`,
    { ...params }
  ),
  initiateLiveSession: (params) => apiClient.post('live/video-sessions/initiate', params),
  getStartedLiveSession: (params) => apiClient.get('live/video-sessions/started', { params }),
  updateLiveSessionUnit: (liveSessionId, params) => apiClient.patch(`live/video-sessions/${liveSessionId}/unit`, { ...params }),
  notifyAttendees: (liveSessionId) => apiClient.post(`/live/video-sessions/${liveSessionId}/notify-attendees`)
};
