import API from '@api/user.api';

import {
  brandingPreviewPlaceholders,
  DEFAULT_ADDRESS
} from '@helpers/constants';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    user: null,
    userSettings: {},
    userPins: [],
    saving: false,
    notifications: {},
    attachments: [],
    loading: false,
    loadingUserSettings: false,
    changeRoleModalData: {},
    userProperties: [],
    todoActiveProperty: null,
    filter: ''
  };
}

const getters = {
  ...pagination.getters,
  currentUser: (state) => state.user,
  currentUserHasRole: (state) => (role) => {
    const { roles } = state.user;

    return roles.map((r) => r.name).includes(role);
  },
  isCorpOrManager(state) {
    return ['corp', 'manager'].includes(state.user.activeRole);
  },
  isOwner: (state, moduleGetters) => moduleGetters.currentUserHasRole('owner'),
  userRoles: (state) => state.user.roles,
  userSettings: (state) => state.userSettings,
  userPins: (state) => state.userPins,
  saving: (state) => state.saving,
  attachments: (state) => state.attachments,
  isLoading: (state) => state.loading,
  loadingUserSettings: (state) => state.loadingUserSettings,
  reviewProcessEnabled: (state, moduleGetters, rootState, rootGetters) => {
    const isAgent = moduleGetters.isUserActiveRoleAgent;
    return isAgent ? state.user?.activeProperty?.reviewProcess : rootGetters['property/propertyReviewProcessEnabled'];
  },
  changeRoleModalData: (state) => state.changeRoleModalData,
  userProperties: (state) => state.userProperties,
  filter: (state) => state.filter,
  isUserActiveRoleAgent: (state) => state.user.activeRole === 'agent',
  userPropertiesCount: (state) => state.user.managedPropertyCount,
  todoActiveProperty: (state) => state.todoActiveProperty,
  isUnitDataEnabled: (state) => state.user?.activeProperty?.unitDataEnabled
};

const mutations = {
  ...pagination.mutations,
  SET_CURRENT_USER(state, payload) {
    state.user = payload;
  },
  SET_USER_SETTINGS(state, payload) {
    state.userSettings = payload;
  },
  SET_SAVING(state, saving) {
    state.saving = saving;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOADING_USER_SETTINGS(state, loading) {
    state.loadingUserSettings = loading;
  },
  SET_ROLE_MODAL_DATA(state, payload) {
    state.changeRoleModalData = payload;
  },
  ADD_ATTACHMENT(state, record) {
    state.attachments.unshift(record);
  },
  SET_ATTACHMENTS(state, records) {
    state.attachments = records;
  },
  SET_USER_PINS(state, records) {
    state.userPins = records;
  },
  SET_USER_PROPERTIES(state, payload) {
    state.userProperties = payload;
  },
  ADD_USER_PROPERTIES(state, payload) {
    state.userProperties.push(...payload);
  },
  SET_FILTER(state, payload) {
    state.filter = payload;
  },
  UPDATE_LAST_LOADED_ATTACHMENT(state, record) {
    const uploadedAttachment = state.attachments[0];

    uploadedAttachment.previewImg = null;
    uploadedAttachment.id = record.id;
    uploadedAttachment.format = record.format;
    uploadedAttachment.originalUrl = record.originalUrl;
    uploadedAttachment.resizedUrl = record.resizedUrl;
    uploadedAttachment.showProgressBar = false;
  },
  SET_TODO_ACTIVE_PROPERTY(state, property) {
    state.todoActiveProperty = property;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setCurrentUser({ commit }, user) {
    commit('SET_CURRENT_USER', user);
  },
  async getUserDefaultAddress({ state }) {
    const { data } = await API.getUserBrandingSettings({ propertyId: state.user.activeProperty.id });

    if (data && data.defaultAddress) return data.defaultAddress;

    return DEFAULT_ADDRESS;
  },
  async getUserSettings({ commit }) {
    commit('SET_LOADING_USER_SETTINGS', true);

    const response = await API.getUserSettings();

    if (response.status === 200) {
      commit('SET_USER_SETTINGS', response.data.record);
    }

    commit('SET_LOADING_USER_SETTINGS', false);
  },
  async updatePassword({ commit }, params) {
    commit('SET_SAVING', true);

    const response = await API.updatePassword(params);

    commit('SET_SAVING', false);

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async setResizedPhoto({ state }, params) {
    const response = await API.setResizedPhoto(params);

    return response;
  },
  async updateCurrentUser({ commit }) {
    const response = await API.sessionCheck();

    const { user } = response.data;

    localStorage.user = JSON.stringify(user);
    commit('SET_CURRENT_USER', user);
  },
  async getUserBrandingSettings({ commit, getters: moduleGetters, rootState }, managedPropertyId) {
    let propertyId;

    if (moduleGetters.isUserActiveRoleAgent) {
      propertyId = moduleGetters.currentUser.activeProperty.id;
    } else if (moduleGetters.isCorpOrManager) {
      propertyId = managedPropertyId;
    }

    if (propertyId) {
      const response = await API.getUserBrandingSettings({ propertyId });

      if (response.status === 200) {
        const data = {
          ...rootState.branding.brandingData,
          ...response.data,
          previewPlaceholder: brandingPreviewPlaceholders.video
        };

        const defaultBrandingData = { ...data };

        commit(
          'branding/SET_BRANDING_DATA',
          { ...data, defaultBrandingData },
          { root: true }
        );
      }

      return response?.data;
    }

    return null;
  },
  async saveUserSettings({ commit }, params) {
    commit('SET_SAVING', true);

    const response = await API.saveUserSettings(params);

    return response;
  },
  async getNotifications() {
    const response = await API.getNotifications();

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async updateNotification({ state }, params) {
    const response = await API.updateNotification(params);

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async updateSecondaryEmails({ state }, params) {
    const response = await API.updateSecondaryEmails(params);

    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateAutoCaptions({ }, params) {
    const response = await API.updateCompanySettings(params.companyId, params.form);

    return response;
  },
  addAttachment({ commit }, record) {
    commit('ADD_ATTACHMENT', record);
  },
  async getAttachments({ commit }) {
    commit('SET_LOADING', true);

    const { data: { records } } = await API.getUserAttachments();

    commit('SET_ATTACHMENTS', records);
    commit('SET_LOADING', false);
  },
  async updateAttachmentTitle({ commit }, params) {
    commit('SET_SAVING', true);

    await API.updateAttachmentTitle(params);

    commit('SET_SAVING', false);
  },
  async saveAttachment({ commit }, params) {
    const { data: { record } } = await API.saveAttachment(params);

    const timeout = record.format === 'pdf' ? 0 : 8000;

    return new Promise((resolve) => {
      setTimeout(() => {
        commit('UPDATE_LAST_LOADED_ATTACHMENT', record);

        return resolve();
      }, timeout);
    });
  },
  async deleteAttachment({ state, commit }, id) {
    await API.deleteAttachment(id);

    const attachments = state.attachments.filter((el) => el.id !== id);

    commit('SET_ATTACHMENTS', attachments);
  },
  async saveEmailCta({ commit }, params) {
    commit('SET_SAVING', true);

    await API.saveEmailCta(params);

    commit('SET_SAVING', false);
  },
  setSaving({ commit }, saving) {
    commit('SET_SAVING', saving);
  },
  resetUser({ commit }) {
    commit('RESET');
  },
  async getUserPins({ commit }) {
    const response = await API.getUserPins();

    commit('SET_USER_PINS', response.data.records);
  },
  // eslint-disable-next-line no-unused-vars
  async apiGetUserProperties({ state }, params) {
    const response = API.getProperties(params);

    return response;
  },
  async applyPropertiesFilter({ state, commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const { data } = await dispatch('apiGetUserProperties', {
      page: 1,
      perPage: 6,
      name: params.filter,
      ...(params?.requestRole && {
        requestRole: params.requestRole
      })
    });

    commit('SET_PAGE', 1);
    commit('SET_FILTER', params.filter);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_USER_PROPERTIES', data.records);
    commit('SET_LOADING', false);
  },
  async fetchMoreUserProperties({ state, commit, dispatch }, params) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const { data } = await dispatch('apiGetUserProperties', {
      page: nextPage,
      perPage: 6,
      name: state.filter,
      ...(params?.requestRole && {
        requestRole: params.requestRole
      })
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_USER_PROPERTIES', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  // eslint-disable-next-line no-unused-vars
  async deletePin({ state }, payload) {
    await API.deletePin(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async putPins({ state }, payload) {
    await API.putPins({ pins: payload });
  },
  setRoleModalData({ commit }, payload) {
    commit('SET_ROLE_MODAL_DATA', payload);
  },
  async setActiveUserRole({ commit }, payload) {
    commit('SET_LOADING', true);
    const response = await API.setActiveUserRole({ role: payload });

    commit('SET_LOADING', false);
    return response;
  },
  async setActiveUserProperty({ commit }, id) {
    commit('SET_LOADING', true);
    const response = await API.updateActiveProperty({ managedPropertyId: id });

    commit('SET_LOADING', false);
    return response;
  },
  async setTodoActiveProperty({ commit }, property) {
    await commit('SET_TODO_ACTIVE_PROPERTY', property);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
