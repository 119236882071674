import apiClient from './apiClient';

export default {
  createPlaylist: (params) => apiClient.post('/pre-recorded-video/playlist', { ...params }),
  updatePlaylist: (playlistId, params) => apiClient.put(`/pre-recorded-video/playlists/${playlistId}`, { ...params }),
  copyPlaylist: (id) => apiClient.post(`/pre-recorded-video/playlists/${id}/copy`),
  getPlaylistStats: (playlistId, params) => apiClient.get(`/pre-recorded-video/playlists/${playlistId}/breakdown`, { params }),
  getPlaylist: (playlistId) => apiClient.get(`/pre-recorded-video/playlists/${playlistId}`),
  getPlaylistPreview: (playlistId) => apiClient.get(`/pre-recorded-video/playlists/${playlistId}/preview`),
  getPlaylistStatistic: (playlistId) => apiClient.get(`/pre-recorded-video/playlists/${playlistId}/statistic`),
  getRecipientsByPackage: (playlistId, params) => apiClient.post(`/pre-recorded-video/playlist/${playlistId}/links`, { ...params }),
  getPlaylistsList: (params) => apiClient.get('/pre-recorded-video/playlists', { params }),
  getAttachments: (playlistId) => apiClient.get(`/pre-recorded-video/playlists/${playlistId}/attachments`),
  addVideoToPlaylist: (params) => apiClient.put(`/pre-recorded-video/${params.videoId}/playlists/${params.playlistId}`, {}, { silentErrorNotification: true }),
  addMultipleVideosToPlaylist: (playlistId, params) => apiClient.put(`/pre-recorded-video/playlists/${playlistId}/videos`, { ...params }, { silentErrorNotification: true })
};
